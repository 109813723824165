@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Montserrat;
  src: url(../public/fonts/Montserrat-VariableFont_wght.ttf);
}

body {
  font-family: 'Montserrat';
  min-width: 320px;
  background-color: #fff;
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
