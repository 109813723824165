.swiper-pagination-bullet {
  width: 8px !important;
  height: 8px !important;
  border-radius: 4px !important;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
  transition: width 0.2s;
}

.swiper-pagination-bullet-active {
  width: 30px !important;
  height: 8px !important;
  color: #fff;
  background: #df3e23 !important;
  transition: width 0.2s;
}

/* .swiper-pagination {
    width: 100px !important;
} */
.content-news * {
  max-width: 100%;
  font-family: 'Montserrat' !important;
}

.content-news img {
  padding: 15px 0;
}
:root {
  --color-main: #323637;
  --color-main-2: #df3e23;
}
.bg-brand-1 {
  background: var(--color-main);
}

.\!bg-brand-1 {
  background-color: var(--color-main) !important;
}
.bg-cam {
  background-color: var(--color-main-2);
}
.color-main {
  color: var(--color-main);
}
.color-white {
  color: #fff;
}
